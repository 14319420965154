import axios from 'axios'
import qs from 'qs'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import * as Sentry from '@sentry/browser'

import router from '@/router'
import store from '@/store'

function getHttpInst() {
    const instance = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        headers: {
            ...(store.state.auth.jwt?.access && {
                Authorization: `JWT ${store.state.auth.jwt.access}`,
            }),
        },
        paramsSerializer: params =>
            qs.stringify(params, {
                arrayFormat: 'repeat',
            }),
    })
    instance.interceptors.response.use(
        response => response,
        error => {
            if (error?.response?.status === 403) {
                Sentry.captureEvent({
                    message: 'Forced user log out',
                })
                store.dispatch('auth/logout')
                router.push({ name: 'login' }, () => {
                    location.reload()
                })
            } else {
                return Promise.reject(error)
            }
        }
    )
    return instance
}

function get(url, config = {}) {
    return refreshToken().then(() => getHttpInst().get(url, config))
}

function post(url, body, config = {}) {
    return refreshToken().then(() => getHttpInst().post(url, body, config))
}

function put(url, body, config = {}) {
    return refreshToken().then(() => getHttpInst().put(url, body, config))
}

function patch(url, body, config = {}) {
    return refreshToken().then(() => getHttpInst().patch(url, body, config))
}

function drop(url, config = {}) {
    return refreshToken().then(() => getHttpInst().delete(url, config))
}

async function getOverwrittenConfig() {
    const tokenOverwritten = JSON.parse(localStorage.getItem('JWT-overwritten'))

    if (!tokenOverwritten) {
        return undefined
    }

    let accessToken

    if (moment.unix(jwtDecode(tokenOverwritten.access).exp).isAfter()) {
        accessToken = tokenOverwritten.access
    } else if (moment.unix(jwtDecode(tokenOverwritten.refresh).exp).isAfter()) {
        try {
            const { data } = await axios.post(
                process.env.VUE_APP_JWT_API_URL + 'refresh/',
                JSON.stringify({ refresh: tokenOverwritten.refresh }),
                { headers: { 'Content-Type': 'application/json' } }
            )

            if (data.access) {
                localStorage.setItem('JWT-overwritten', JSON.stringify(data))
                accessToken = data.access
            }
        } catch (error) {
            return undefined
        }
    }

    return accessToken
        ? { headers: { Authorization: `JWT ${accessToken}` } }
        : undefined
}

function refreshToken() {
    if (store.state.auth.jwt?.access) {
        return store.dispatch('auth/refreshTokenIfNecessary')
    }
    return Promise.resolve()
}

export default {
    get,
    post,
    put,
    drop,
    patch,
    getOverwrittenConfig,
}
